import pts from 'OK/pts!webpush';
import logger from 'OK/logger';
import utils from 'OK/utils/utils';
import uuid from 'OK/uuid';
import vanilla from 'OK/utils/vanilla';

var WEB_PUSH_DEVICE_ID_KEY = 'webpush-device-id',
    WEB_PUSH_ENDPOINT_KEY = 'webpush-endpoint',
    MESSENGER_NOTIFICATIONS_KEY = 'notification_enabled_messenger';

var STATUS_DEFAULT = 'DEFAULT',
    STATUS_DENIED = 'DENIED',
    STATUS_GRANTED = 'GRANTED',
    STATUS_SUBSCRIBED = 'SUBSCRIBED',
    STATUS_UNSUBSCRIBED = 'UNSUBSCRIBED';

var SYNC_URL = '/web-api/webpush/sync';

/**
 * @type {null|string}
 */
var applicationServerKey = null;

/**
 * @type {boolean}
 */
var serviceWorkerIsEnabled = false;

/**
 * @type {string|null}
 */
var serverStatus = null;

/**
 * @return {boolean} whether web push api is supported in current browser or not
 * @public
 */
function isSupported() {
    return 'PushManager' in window && 'serviceWorker' in navigator && window.Notification && 'localStorage' in window;
}

/**
 * @return {boolean}
 * @public
 */
function isEnabled() {
    return isSupported() && serviceWorkerIsEnabled;
}

/**
 * @param element
 * @public
 */
function activate(element) {
    if (!isSupported()) {
        return;
    }

    serviceWorkerIsEnabled = element.getAttribute('data-service-worker-is-enabled') === 'true';
    applicationServerKey = element.getAttribute('data-application-server-key');
    serverStatus = element.getAttribute('data-server-status');

    var messageNotificationsEnabled = element.getAttribute('data-message-notifications-enabled') === 'true';
    var extendedSettingsEnabled = element.getAttribute('data-extended-settings-enabled') === 'true';
    if (extendedSettingsEnabled && serverStatus === STATUS_SUBSCRIBED) {
        toggleMessageNotifications(messageNotificationsEnabled);
    }

    localStorageRemove('webpush-activated');

    if (!serviceWorkerIsEnabled) {
        debug('Service worker is disabled');
        unsubscribe()['catch'](debug);
        return;
    }

    getStatus().then(function (status) {
        // try to sync subscription status between browser and server

        if (status === STATUS_SUBSCRIBED) {
            debug('Subscription is active');

            return getSubscription().then(function (subscription) {
                if (serverStatus !== STATUS_SUBSCRIBED || getLastSubmittedEndpoint() !== subscription.endpoint) {
                    debug('Subscription has been changed');
                    logger.success('webpush.subscription-changed');

                    syncSubscription(STATUS_SUBSCRIBED, subscription);
                }
            });
        }

        if (status === STATUS_GRANTED) {
            if (serverStatus !== status) {
                syncSubscription(status);
            }

            return;
        }

        // user revoked permission, so we need to remove service worker and notify server about this

        debug('Permission has been revoked');
        removeLastSubmittedEndpoint();
        toggleMessageNotifications(false);

        logger.success('webpush.subscription-revoked');

        if (serverStatus !== status) {
            return syncSubscription(status);
        }
    }, debug);
}

/**
 * @public
 */
function deactivate() {}

/**
 * @return Promise
 * @public
 */
function subscribe(place) {
    place = place || 'unknown';

    if (!isEnabled()) {
        return Promise.reject("Module is disabled");
    }

    return getStatus()
        .then(function (status) {
            if (status === STATUS_DENIED) {
                throw new Error("User revoked permissions");
            }

            if (status === STATUS_SUBSCRIBED) {
                return status;
            }

            return requestPermission()
                .then(registerServiceWorker)
                .then(createSubscription)
                .then(function (subscription) {
                    if (place !== 'messages') {
                        toggleMessageNotifications(true);
                        notifyAboutEnabling();
                    }

                    logger.success('webpush.' + place + '.subscribe');

                    return syncSubscription(STATUS_SUBSCRIBED, subscription, place, true);
                }, function () {
                    logger.error('webpush.' + place + '.subscribe');
                });
        });
}

/**
 * @return {Promise}
 * @public
 */
function unsubscribe(place) {
    place = place || 'unknown';

    debug('Unsubscribe from notifications');

    if (!isSupported()) {
        return Promise.reject("Push API is not supported");
    }

    return getSubscription()
        .then(function (subscription) {
            if (subscription !== null) {
                subscription.unsubscribe().then(debug);
            }

            if (place !== 'messages') {
                toggleMessageNotifications(false);
            }

            logger.success('webpush.' + place + '.unsubscribe');

            removeLastSubmittedEndpoint();

            return syncSubscription(STATUS_UNSUBSCRIBED, subscription, place, true);
        }, function (e) {
            logger.error('webpush.' + place + '.unsubscribe');
            debug(e);
            throw e;
        });
}

/**
 * @return {Promise}
 * @public
 */
function getStatus() {
    if (!Notification) {
        return Promise.resolve(STATUS_DENIED);
    }

    if (Notification.permission === 'denied') {
        return Promise.resolve(STATUS_DENIED);
    }

    if (Notification.permission === 'default') {
        return Promise.resolve(STATUS_DEFAULT);
    }

    return getSubscription().then(function (subscription) {
        return subscription ? STATUS_SUBSCRIBED : STATUS_GRANTED;
    });
}

/**
 * @return {Promise}
 */
function requestPermission() {
    if (Notification) {
        if (Notification.permission === 'default') {
            return new Promise(function (resolve, reject) {
                Notification.requestPermission(function (permission) {
                    if (permission === 'granted') {
                        logger.success('webpush.request.granted');
                        resolve(STATUS_GRANTED);
                    } else {
                        logger.success('webpush.request.denied');
                        reject(STATUS_DENIED);
                    }
                });
            });
        } else if (Notification.permission === 'granted') {
            return Promise.resolve(STATUS_GRANTED);
        }
    }

    return Promise.reject(STATUS_DENIED);
}

/**
 * @return {Promise}
 */
function registerServiceWorker() {
    debug('Register service worker');

    //swPath надо искать в Head.html, он там объявлен как глабольная переменная
    return navigator.serviceWorker.register(swPath)
        .then(function (registration) {

            if (registration.installing) {
                debug('Service worker installing. scope = ' + registration.scope);
            } else if (registration.waiting) {
                debug('Service worker installed');
            } else if (registration.active) {
                debug('Service worker active');
            }

            if (!(registration.showNotification)) {
                logger.error('webpush.support.showNotifications');
                throw new Error('Notifications aren\'t supported on service workers.');
            }

            if (Notification.permission === 'denied') {
                logger.error('webpush.denied');
                throw new Error('The user has blocked notifications.');
            }

            if (!('PushManager' in window)) {
                logger.error('webpush.support.pushManager');
                throw new Error('Push messaging isn\'t supported');
            }

            return navigator.serviceWorker.ready;
        });
}

/**
 * @param registration
 */
function createSubscription(registration) {
    debug('Subscribe to notifications');

    return registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: utils.urlBase64ToUint8Array(applicationServerKey)
    });
}

/**
 * @return {Promise}
 */
function getSubscription() {
    var promise = navigator.serviceWorker.getRegistration();
    if (promise) {
        return promise.then(function (registration) {
            return registration && registration.pushManager
                ? registration.pushManager.getSubscription()
                : null;
        });
    }

    return Promise.resolve();
}

/**
 * @param {string} status
 * @param {Object} [subscription]
 * @param {string} [place]
 * @param {boolean} [userAction]
 */
function syncSubscription(status, subscription, place, userAction) {
    debug("Sync subscription");

    var state = {
        status: status,
        place: place || 'unknown',
        userAction: userAction
    };

    if (subscription) {
        state['subscription'] = {
            deviceId: getDeviceId(),
            endpoint: subscription.endpoint,
            auth: encodeKey(subscription, 'auth'),
            p256dh: encodeKey(subscription, 'p256dh')
        };
    }

    return vanilla.ajax({
        method: 'POST',
        url: SYNC_URL,
        headers: {'Content-Type': 'application/json'},
        data: JSON.stringify(state)
    }).then(function () {
        if (subscription) {
            setLastSubmittedEndpoint(subscription.endpoint);
        }

        return status;
    });
}

/**
 * @return {string}
 */
function getLastSubmittedEndpoint() {
    return localStorageGet(WEB_PUSH_ENDPOINT_KEY);
}

/**
 * @param {string} endpoint
 */
function setLastSubmittedEndpoint(endpoint) {
    localStorageSet(WEB_PUSH_ENDPOINT_KEY, endpoint);
}

/**
 */
function removeLastSubmittedEndpoint() {
    localStorageRemove(WEB_PUSH_ENDPOINT_KEY);
}

/**
 * @param enabled
 */
function toggleMessageNotifications(enabled) {
    localStorageSet(MESSENGER_NOTIFICATIONS_KEY, enabled ? '1' : '0');
}

/**
 * Encode subscription key to string
 *
 * @param subscription
 * @param key
 * @return {string}
 */
function encodeKey(subscription, key) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey(key))));
}

/**
 * @return {string} unique device id
 * @private
 */
function getDeviceId() {
    var value = localStorageGet(WEB_PUSH_DEVICE_ID_KEY);
    if (!value) {
        value = uuid.generate();
        localStorageSet(WEB_PUSH_DEVICE_ID_KEY, value);
    }

    return value;
}

/**
 * @param {string} key
 * @param {*} [defaultValue]
 * @return {*}
 * @private
 */
function localStorageGet(key, defaultValue) {
    if (!localStorage) {
        return null;
    }

    try {
        return localStorage.getItem(key);
    } catch (e) {
        logger.error('webpush.localStorage-get-' + (e.name || '' + e));
        return defaultValue;
    }
}

/**
 * @param {string} key
 * @param {*} value
 * @private
 */
function localStorageSet(key, value) {
    if (!localStorage) {
        return;
    }

    try {
        localStorage.setItem(key, value);
    } catch (e) {
        logger.error('webpush.localStorage-set-' + (e.name || '' + e));
    }
}

/**
 * @param {string} key
 */
function localStorageRemove(key) {
    if (!localStorage) {
        return;
    }

    try {
        localStorage.removeItem(key);
    } catch (e) {
        logger.error('webpush.localStorage-remove-' + (e.name || '' + e));
    }
}

/**
 * @private
 */
function debug() {
    if (OK.fn.isDebug()) {
        if (arguments[0] instanceof Error) {
            console.error.apply(console, arguments);
        } else {
            var args = Array.prototype.slice.apply(arguments);
            args.unshift('[webpush]');

            console.log.apply(console, args);
        }
    }
}

/**
 * @private
 */
function notifyAboutEnabling() {
    if (!Notification) {
        return;
    }

    var title = pts.getLMsg("notification.enabled.title"),
        body = pts.getLMsg("notification.enabled.body"),
        icon = OK.cnst.staticUrl + "res/i/html5_notif_icon.png",
        tag = "messenger-enabled";

    return new Notification(title, {
        body: body,
        icon: icon,
        tag: tag
    });
}

export default { STATUS_DEFAULT, STATUS_GRANTED, STATUS_DENIED, STATUS_SUBSCRIBED, STATUS_UNSUBSCRIBED, isSupported, isEnabled, activate, deactivate, subscribe, unsubscribe, getStatus };

export { STATUS_DEFAULT, STATUS_GRANTED, STATUS_DENIED, STATUS_SUBSCRIBED, STATUS_UNSUBSCRIBED, isSupported, isEnabled, activate, deactivate, subscribe, unsubscribe, getStatus };
